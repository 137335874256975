import React, { useEffect, useState } from "react";

import type { ReactNode } from "react";
import type { Article, Group, Resource, StoryVignette } from "../../client";

import { fetchArticles } from "../../client";
import { isArticle } from "../../helpers";
import { useGlobalStore } from "../GlobalStateContainer/store";

export function SideBarSubscriptionsContainer({
  children,
  limit
}: {
  limit: number;
  children: (feed: Array<Group>) => ReactNode;
}) {
  const [groups, setGroups] = useState([] as Array<Group>);
  const {
    exchangeToken,
    personalizedContent: { subscribedTopics = [] }
  } = useGlobalStore();
  const topics = subscribedTopics
    .map(subscription => subscription.topic_id)
    .sort();

  useEffect(() => {
    if (topics.length === 0) {
      setGroups([]);
      return;
    }
    (async () => {
      const articles = await fetchArticles({
        sort: "latest",
        limit,
        topics,
        exchangeToken
      });
      setGroups(
        articles.articles.map(group =>
          enhanceArticlesStoryVignetteInGroup(topics, group)
        )
      );
    })().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics.join(","), limit]);

  return <>{children(groups)}</>;
}

function enhanceArticlesStoryVignetteInGroup(
  topics: string[],
  group: Group
): Group {
  return group.map(component => {
    if (!isArticle(component)) {
      return component;
    }
    return enhanceArticleStoryVignette(topics, component);
  });
}

function enhanceArticleStoryVignette(
  topics: string[],
  article: Article
): Article {
  const topicInArticleWhichIsSubscribed = article.tags
    .filter(tag => tag.type !== "machine")
    .find(topic => topics.includes(topic.topic_id));

  if (!topicInArticleWhichIsSubscribed) {
    return article;
  }
  return {
    ...article,
    resources: article.resources.map(resource => {
      if (!isStoryVignette(resource)) {
        return resource;
      }
      return {
        ...resource,
        id: topicInArticleWhichIsSubscribed.topic_id,
        title: topicInArticleWhichIsSubscribed.title,
        supertag: undefined,
        ref: "story"
      };
    })
  };
}

export function isStoryVignette(resource: Resource): resource is StoryVignette {
  return resource.type === "StoryVignette";
}
