import React, { useState } from "react";

import type { ReactNode } from "react";

import { ActiveArticleContext } from "../../contexts/ActiveArticleContext";

export const ActiveArticleContainer = ({
  children
}: {
  children: ReactNode;
}) => {
  const [refs, setActiveRef] = useState<HTMLElement[]>([]);
  const [activeArticleIds, setActiveArticle] = useState<string[]>([]);

  const openActiveArticle = (
    article_id: string,
    activeArticleRef?: HTMLElement
  ) => {
    if (!activeArticleRef) {
      return;
    }
    setActiveRef([...refs, activeArticleRef]);
    setActiveArticle([...activeArticleIds, article_id]);
  };

  const closeActiveArticle = (
    article_id: string,
    activeArticleRef?: HTMLElement
  ) => {
    if (activeArticleRef) {
      setActiveRef(refs.filter(ref => ref !== activeArticleRef));
    }

    setActiveArticle(activeArticleIds.filter(id => id !== article_id));
  };

  return (
    <ActiveArticleContext.Provider
      value={{
        article_ids: activeArticleIds,
        refs: refs,
        openActiveArticle,
        closeActiveArticle
      }}
    >
      {children}
    </ActiveArticleContext.Provider>
  );
};
