import React from "react";

import type { Topic } from "../../../client";

import { SideBarTopic } from "./SideBarTopic";

export function SideBarTopics({
  header,
  topics,
  getMore
}: {
  header: string;
  topics: Topic[];
  getMore: () => void;
}) {
  return (
    <section className="current box">
      {topics.length > 0 && (
        <>
          <h2 className="box-heading u-fadehr--after">{header}</h2>
          <div className="current-list box-main" data-section_source="current">
            {topics.map(topic => (
              <SideBarTopic key={topic.topic_id} topic={topic} />
            ))}
          </div>
          <div className="box-footer">
            <button
              className="btn  btn--light btn--secondary btn--full"
              onClick={e => getMore()}
            >
              Visa fler
            </button>
          </div>
        </>
      )}
    </section>
  );
}
