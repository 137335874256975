import React from "react";

import type { ReactNode } from "react";

type LayoutContextType = {
  headerGradientClass?: string;
  headerRightNav?: () => ReactNode;
};

export const LayoutContext = React.createContext<LayoutContextType>({});
