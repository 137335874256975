import React from "react";

import type { ReactNode } from "react";
import type { Article, Group } from "../../client";

import { ArticleContext } from "../../contexts/ArticleContext";

export const ArticleContainer = ({
  children,
  article,
  loadStoryArticles,
  lazy = false
}: {
  children: ReactNode;
  article: Article;

  loadStoryArticles?: () => Promise<Array<Group>>;
  lazy?: boolean;
}) => (
  <ArticleContext.Provider value={{ article, lazy, loadStoryArticles }}>
    {children}
  </ArticleContext.Provider>
);
