import { getConfig } from "./getConfig";

export enum FeatureEnum {
  BOOKMARKS = "bookmarks"
}

const config = getConfig();

export function hasFeature(requestedFeature: FeatureEnum) {
  return config.features.find(
    configuredFeature => requestedFeature === configuredFeature
  );
}
