import React from "react";

import type { Group } from "../../../client";

import { NotLoggedIn } from "./NotLoggedIn";
import { Empty } from "./Empty";
import { Feed } from "./Feed";
import { useGlobalStore } from "../../../containers/GlobalStateContainer/store";

export function SideBarSubscriptions({ feed }: { feed: Array<Group> }) {
  const {
    isLoggedIn,
    personalizedContent: { subscribedTopics = [] }
  } = useGlobalStore();

  if (!isLoggedIn) {
    return <NotLoggedIn />;
  }
  if (subscribedTopics.length === 0) {
    return <Empty />;
  }
  return <Feed feed={feed} />;
}
