import React, { useEffect, useState } from "react";
import Script from "next/script";
import { useRouter } from "next/router";

import type { NextRouter } from "next/router";
import type { ReactNode } from "react";
import type { Article } from "../../client";

import { OrvestoContext } from "../../contexts/OrvestoContext";
import { useCookiePermission } from "../../hooks/cookiePermission/useCookiePermission";
import { getConfig } from "../../config";

const getOrvestoTrackerScript = (
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  cpid?: string
): any => {
  if (!cpid || typeof window === "undefined") {
    return;
  }
  return (
    <Script
      type="text/javascript"
      async
      src={
        ("https:" === document.location.protocol ? "https://" : "http://") +
        `analytics.codigo.se/j/${cpid}.js`
      }
      onLoad={() => {
        setIsLoaded(true);
      }}
    />
  );
};

const getOrvestoClient = () => {
  if (typeof window === "undefined") {
    return;
  }
  return window._cInfo;
};

function trackOpenedArticle(orvestoTrackingIsAllowed: boolean) {
  return (article: Article) => {
    if (!orvestoTrackingIsAllowed) {
      return;
    }

    const tracker = getOrvestoClient();

    if (!tracker) {
      return;
    }

    tracker.push(
      {
        cmd: "_trackContentPath",
        val: `${article.category.title}/${
          article.story?.title || article.title.value
        }`
      },
      { cmd: "_executeTracking" }
    );
  };
}

function viewHandler(router: NextRouter) {
  switch (router.pathname) {
    case "/":
      return trackView("start");
    case "/category":
      return trackView(router.asPath.replace(/\//g, ""));
    case "/latest":
      return trackView(router.asPath.replace(/\//g, ""));
    case "/topic":
      const topicTitle = Array.isArray(router.query.slug)
        ? router.query.slug[0]
        : router.query.slug;
      return trackView(topicTitle as string);
    default:
      return null;
  }
}

function trackView(view: string): void {
  const tracker = getOrvestoClient();
  tracker.push(
    { cmd: "_trackContentPath", val: `${view}` },
    { cmd: "_executeTracking" }
  );
}

export const OrvestoTrackerContainer = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const orvestoTrackingIsAllowed = useCookiePermission("CMP:analytics");
  const { orvesto_cpid } = getConfig();
  const router = useRouter();

  useEffect(() => {
    if (orvestoTrackingIsAllowed && isLoaded) {
      viewHandler(router);
    }
  }, [orvestoTrackingIsAllowed, isLoaded, router]);

  return (
    <>
      {orvestoTrackingIsAllowed &&
        getOrvestoTrackerScript(setIsLoaded, orvesto_cpid)}
      <OrvestoContext.Provider
        value={{
          trackOpenedArticle: trackOpenedArticle(!!orvestoTrackingIsAllowed)
        }}
      >
        {children}
      </OrvestoContext.Provider>
    </>
  );
};
