import { createContext } from "react";

export type ActiveArticleType = {
  article_ids: string[];
  refs: HTMLElement[];
  openActiveArticle: (
    article_id: string,
    activeArticleRef?: HTMLElement
  ) => void;
  closeActiveArticle: (
    article_id: string,
    activeArticleRef?: HTMLElement
  ) => void;
};

export const ActiveArticleContext = createContext({
  article_ids: [] as string[],
  refs: [] as HTMLElement[]
} as ActiveArticleType);
