import React, { useEffect, useState } from "react";

import type { ReactNode } from "react";
import type { Topic, topicSort } from "../../client";

import { fetchTopicsWithCache } from "../../client";

export function SideBarTopicsContainer({
  children,
  limit: initLimit,
  sort
}: {
  limit: number;
  sort: topicSort;
  children: (topics: Array<Topic>, getMore: () => void) => ReactNode;
}) {
  const [topics, setTopics] = useState([] as Array<Topic>);
  const [limit, setLimit] = useState(initLimit);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    (async () => {
      const topicResponse = await fetchTopicsWithCache({
        limit,
        sort,
        signal
      });
      topicResponse?.topics && setTopics(topicResponse.topics);
    })().catch(console.error);

    return () => abortController.abort();
  }, [sort, limit]);

  const getMore = () => {
    setLimit(limit => limit + 5);
  };

  return <>{children(topics, getMore)}</>;
}
