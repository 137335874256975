import { createContext } from "react";

import type { Article } from "../client";

type defaultOrvestoContext = {
  trackOpenedArticle: (article: Article) => void;
};

export const OrvestoContext = createContext<defaultOrvestoContext>({
  trackOpenedArticle: () => {}
});
