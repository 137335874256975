import { createContext } from "react";

import type { Article } from "../client";

type defaultPixelContext = {
  trackOpenedArticleTags: (article: Article) => void;
};

export const FBPixelContext = createContext<defaultPixelContext>({
  trackOpenedArticleTags: () => {}
});
