import React, { useContext } from "react";

import type { Topic } from "../client";

import { PremiumLockContext } from "../containers/PremiumLockContainer";
import { isFullWidth } from "./Article/Resources/helpers/styling";
import { ArticleContext } from "../contexts/ArticleContext";
import { TrackingContext } from "../contexts/TrackingContext";
import { useSubcribedTopics } from "../hooks/useSubcribedTopics";

export function StarBox({
  topic,
  decoration
}: {
  topic: Topic;
  decoration?: React.CSSProperties;
}) {
  const { isSubscribedToTopic, addSubscription, removeSubscription } =
    useSubcribedTopics();
  const { checkLock } = useContext(PremiumLockContext);
  const { article } = useContext(ArticleContext);
  const { fav, unfav } = useContext(TrackingContext);
  const subscribed = isSubscribedToTopic(topic);

  const onClick = () => {
    if (checkLock()) {
      return;
    }
    if (subscribed) {
      removeSubscription(topic);
      unfav(topic.topic_id, article);
    } else {
      addSubscription(topic);
      fav(topic.topic_id, article);
    }
  };

  return (
    <div
      className={`starbox ${
        isFullWidth(decoration) ? "resource--fullwidth" : ""
      }`}
      style={decoration}
    >
      <button
        data-testid="starbox-star"
        onClick={onClick}
        className={`btn btn--clean starbox-star`}
        aria-label={`Bevaka ${topic.title}`}
      >
        <i
          className={`icon icon--star${
            subscribed ? "--active starbox-star--active" : ""
          }`}
        />
      </button>
    </div>
  );
}
