import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

type tabToSearch = "topics" | "articles";
type formType = "simple" | "large" | "padded";
export function SearchForm({
  placeholder = "Sök",
  type = "simple",
  defaultSearchTab = "articles"
}: {
  placeholder?: string;
  type?: formType;
  defaultSearchTab?: tabToSearch;
}) {
  const router = useRouter();
  const queryFromRouter = router.query.q ? String(router.query.q) : "";
  const tabFromRouter: tabToSearch =
    router.query.tab === "topics" ? "topics" : defaultSearchTab;
  const [query, setQuery] = useState(queryFromRouter);

  useEffect(() => {
    setQuery(queryFromRouter);
  }, [queryFromRouter]);
  return (
    <form
      className={`feed-search--form ${
        type ? `feed-search--form--${type}` : ""
      }`}
      action="/sok"
      onSubmit={e => {
        e.preventDefault();
        router.push(`/sok?q=${encodeURIComponent(query)}&tab=${tabFromRouter}`);
      }}
    >
      {(type === "simple" || type === "padded") && (
        <SimpleForm
          value={query}
          onChange={setQuery}
          placeholder={placeholder}
        />
      )}
      {type === "large" && (
        <LargeForm
          value={query}
          onChange={setQuery}
          placeholder={placeholder}
        />
      )}
    </form>
  );
}

function SimpleForm({
  value,
  placeholder,
  onChange
}: {
  value: string;
  onChange: (newValue: string) => void;
  placeholder: string;
}) {
  return (
    <div className="search">
      <button
        className="search-button search-button--left btn btn--clean"
        aria-label="Sök"
      >
        <i className="icon icon--magnify" />
      </button>
      <input
        value={value}
        onChange={e => onChange(e.target.value)}
        name="q"
        className="search-input search-input--btnleft"
        type="text"
        placeholder={placeholder}
        aria-label="Sök"
      />
    </div>
  );
}

function LargeForm({
  value,
  placeholder,
  onChange
}: {
  value: string;
  onChange: (newValue: string) => void;
  placeholder: string;
}) {
  return (
    <>
      <button className="feed-search-button btn btn--clean">
        <i className="icon icon--magnify" />
      </button>
      <input
        value={value}
        onChange={e => onChange(e.target.value)}
        name="q"
        className="feed-search-input"
        type="text"
        placeholder={placeholder}
        aria-label="Sök"
      />
    </>
  );
}
