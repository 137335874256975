import React from "react";

import type { Group } from "../../../client";

import { LinkToSubscriptions } from "../../Link";
import { SubscriptionBox } from "./SubscriptionBox";
import { FeedGroup } from "../../Feed";

export function Feed({ feed }: { feed: Array<Group> }) {
  const offset = 0;
  return (
    <SubscriptionBox>
      <div className="box-main fs-smaller" data-section_source="RightColumn">
        {feed.map((group, index) => (
          <FeedGroup
            isNotExpandable
            key={index}
            group={group}
            offset={offset}
          />
        ))}
      </div>
      <div className="box-footer">
        <LinkToSubscriptions className="btn btn--light btn--secondary btn--full box-main--button">
          Visa fler
        </LinkToSubscriptions>
      </div>
    </SubscriptionBox>
  );
}
