import React from "react";

import { SubscriptionBox } from "./SubscriptionBox";
import { getConfig } from "../../../config";
import { login } from "../../../containers/GlobalStateContainer/userState/actions/login";

export function NotLoggedIn() {
  const { translation, NEXT_PUBLIC_PREMIUM_NEWSROOM } = getConfig();

  return (
    <SubscriptionBox>
      <div className="sublogincta box-main fs-smaller">
        <span className="sublogincta-text">
          <p className="sublogincta-text-vignette">
            {translation.sublogincta_vignette}
          </p>
          <p>{translation.login.subscription}</p>
        </span>
        <div className="box-footer">
          {NEXT_PUBLIC_PREMIUM_NEWSROOM ? (
            <a
              type="button"
              className="btn btn--primary btn--full"
              href="https://om.omni.se/ekonomi"
            >
              Bli medlem
            </a>
          ) : (
            <button
              type="button"
              className="btn btn--primary btn--full"
              onClick={login}
            >
              Logga in
            </button>
          )}
        </div>
      </div>
    </SubscriptionBox>
  );
}
