import React from "react";

export const SponsoredArticleDisclaimer = ({ show }: { show: boolean }) =>
  show ? (
    <p className="resource resource--text resource--disclaimer fs-smaller c--fade">
      Detta är en annons från vår partner, den är inte skriven av journalisterna
      på Omnis redaktion. Innehållet behöver inte nödvändigtvis reflektera
      Omniredaktionens åsikter.
    </p>
  ) : null;
