import { getState } from "../../../../client";
import { getIdentityInstance } from "../utils/getIdentityInstance";

const login = async (): Promise<void> => {
  try {
    const identityInstance = getIdentityInstance();

    if (identityInstance) {
      const currentHref = window.location.href;
      const stateToken = await getState(currentHref);

      identityInstance.login({ state: stateToken });
    }
  } catch (error) {
    console.error(`Error when login: ${error}`);
  }
};

export { login };
