import React, { useContext } from "react";
import Image from "next/image";

import type { Image as ImageType, Topic } from "../../../client";

import { LinkToTopic } from "../../Link";
import { imageSrc, getImageDimensions } from "../../../lib/image";
import { PremiumLockContext } from "../../../containers/PremiumLockContainer";
import { getImageSizes } from "../../../helpers";
import { TrackingContext } from "../../../contexts/TrackingContext";
import { ArticleContext } from "../../../contexts/ArticleContext";
import { useSubcribedTopics } from "../../../hooks/useSubcribedTopics";

const sizes = getImageSizes({ web: "82px", tablet: "12vw", mobile: "25vw" });

const getImageData = (image: ImageType | undefined) => {
  if (!image) {
    return {
      imageUrl: undefined,
      width: undefined,
      height: undefined
    };
  }

  const imageUrl = imageSrc(image, "sidebar", true);
  const { width, height } = getImageDimensions(imageUrl);

  return {
    imageUrl,
    width,
    height
  };
};

export function SideBarTopic({ topic }: { topic: Topic }) {
  const { fav, unfav } = useContext(TrackingContext);
  const { article } = useContext(ArticleContext);
  const { checkLock } = useContext(PremiumLockContext);
  const { isSubscribedToTopic, addSubscription, removeSubscription } =
    useSubcribedTopics();

  const isSubscribed = isSubscribedToTopic(topic);

  const onClick = () => {
    if (checkLock()) {
      return;
    }

    if (isSubscribed) {
      removeSubscription(topic);
      unfav(topic.topic_id, article);
    } else {
      addSubscription(topic);
      fav(topic.topic_id, article);
    }
  };

  const { imageUrl, width, height } = getImageData(topic.image);

  return (
    <div className="topic">
      <LinkToTopic topic={topic} className="a a--primary">
        {topic.image && imageUrl && (
          <Image
            className="topic-image"
            loading="lazy"
            src={imageUrl}
            alt={topic.image.byline ? topic.image.byline.title : ""}
            sizes={sizes}
            width={width}
            height={height}
          />
        )}
        <span className="topic-title">{topic.title}</span>
      </LinkToTopic>
      <button
        className="btn btn--clean topic-star"
        onClick={onClick}
        aria-label={`Bevaka ${topic.title}`}
      >
        <i
          className={`icon icon--star${isSubscribed ? "--active" : ""} ${
            topic.image ? "topic-star--withimage" : ""
          }`}
        />
      </button>
    </div>
  );
}
