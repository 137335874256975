import React from "react";

import type { ReactNode } from "react";

import { TeaserContext } from "../../../contexts/TeaserContext";

export function SubscriptionBox({ children }: { children: ReactNode }) {
  return (
    <TeaserContext.Provider value={{ teaserType: "subscription" }}>
      <section className="subscription box">
        <h2 className="box-heading box--padded u-fadehr--after">
          Mina bevakningar
        </h2>
        {children}
      </section>
    </TeaserContext.Provider>
  );
}
