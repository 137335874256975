import React from "react";

import { SubscriptionBox } from "./SubscriptionBox";

export function Empty() {
  return (
    <SubscriptionBox>
      <div className="box-main box-main--empty fs-smaller">
        <p>
          Hitta ämnen att bevaka <i className="icon icon--star" /> i listorna
          för populära eller aktuella ämnen, eller sök ovan.
        </p>
      </div>
    </SubscriptionBox>
  );
}
