import { createContext } from "react";

export type TeaserTypes =
  | "large"
  | "small"
  | "tiny"
  | "flash"
  | "subscription"
  | "fishstick";
export type TeaserContextType = {
  teaserType: TeaserTypes;
};

export const TeaserContext = createContext<TeaserContextType>(
  {} as TeaserContextType
);
TeaserContext.displayName = "TeaserContext";
