import { createContext } from "react";

import type { Component } from "../client";

interface CanBeAd {
  sponsor?: string;
}

interface canBePassion {
  teaser_layout?: string;
}

type CustomComponent = Component & canBePassion & CanBeAd;
export type CustomGroup = CustomComponent[];

interface TeaserGroupContextType {
  group: CustomGroup;
}

const defaultValue: TeaserGroupContextType = {
  group: []
};

export const TeaserGroupContext = createContext(defaultValue);
